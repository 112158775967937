import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MovieItem from "../components/MovieItem";
import Spinner from "../components/Spinner";
import {
  getMovies,
  schedAnnouncer,
  reset,
} from "../features/movies/movieSlice";
import { isFuture, parseISO } from "date-fns";

function Landing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { movies, isLoading, isError, message } = useSelector(
    (state) => state.movies
  );

  useEffect(() => {
    if (isError) {
      console.log(message);
    }

    if (!user) {
      navigate("/login");
    }

    dispatch(getMovies());
    return () => {
      dispatch(reset());
    };
  }, [user, navigate, isError, message, dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  const onSubmit = (target) => {
    dispatch(schedAnnouncer(target));
  };

  return (
    <>
      <section className="heading">
        <h1>Welcome {user && user.name}</h1>
        <p>Upcoming Movies</p>
      </section>

      <section className="content">
        {movies.length > 0 ? (
          <div className="goalslanding">
            {movies
              .filter((mov) => isFuture(parseISO(mov.datef)))
              .map((movie) => (
                <MovieItem key={movie._id} movie={movie} edit={false} />
              ))
              .reverse()}
          </div>
        ) : (
          <h3> No movies set</h3>
        )}
      </section>

      <section className="buttons">
        <button className="btn btn-block" onClick={() => onSubmit("send")}>
          Send Schedule
        </button>
        <button className="btn btn-block" onClick={() => onSubmit("pre")}>
          Role Call
        </button>
      </section>
    </>
  );
}

export default Landing;
