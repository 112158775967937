import axios from "axios";

const API_URL = "/api/movies/names/"; //change to names

const getNames = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);
  const res = response.data[0].name;
  return res;
};

const namesService = {
  getNames,
};

export default namesService;
