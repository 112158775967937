import { useState } from "react";
import { useDispatch } from "react-redux";
import { createMovie } from "../features/movies/movieSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";

function MovieForm({ names }) {
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [pDate, setpDate] = useState(new Date());
  const [abs, setAbs] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();

    const formatted = format(pDate, "P", { locale: enGB });

    const fields = {
      name: text,
      date: formatted,
      abs,
      title,
    };
    // console.log(fields);
    dispatch(createMovie({ fields }));
    setText("");
    setTitle("");
  };

  return (
    <section className="form">
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <div className="form-group">
            <label htmlFor="text">Name</label>
            <select
              type="text"
              name="text"
              id="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            >
              {names.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <label htmlFor="text">Date / Absence</label>
          <div className="double">
            <DatePicker
              selected={pDate}
              onChange={(date) => setpDate(date)}
              calendarStartDay={1}
              dateFormat="dd/MM/yyyy"
            />
            <input
              type="checkbox"
              id="abs"
              name="abs"
              onChange={(e) => setAbs(!abs)}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="text">Movie</label>
          <input
            type="text"
            name="title"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <button className="btn btn-block" type="submit">
            Add Movie
          </button>
        </div>
      </form>
    </section>
  );
}

export default MovieForm;
