import axios from "axios";

const API_URL = "/api/users/";

//Register user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

//Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + "login", userData);

  if (response.data) {
    const now = new Date();
    const ttl = 60000 * 60 * 24; // 1min * 60 * 24 = 1 day
    const expDate = now.getTime() + ttl;

    response.data.expiresAt = expDate;

    // console.log(response.data);
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = { register, logout, login };

export default authService;
