import { useDispatch } from "react-redux";
import { useState } from "react";
import { deleteMovie, updateMovie } from "../features/movies/movieSlice";

function MovieItem({ movie, edit, names }) {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(movie.name);
  const [title, setTitle] = useState(movie.title);
  const [abs, setAbs] = useState(movie.abs);

  // const handleInputChange = (e) => {
  //   console.log(e.target.value);
  //   // update(movie, newMov);
  // };

  const update = (e) => {
    e.preventDefault();

    const updatedMov = {
      _id: movie._id,
      name: name,
      abs: abs,
      title: title,
    };
    dispatch(updateMovie(updatedMov));
  };

  return (
    <div className={movie.abs ? "goalabs" : "goal"}>
      {isEditing ? (
        <>
          <form onSubmit={update} className="form">
            <div className="goaldivs">{movie.date} </div>
            <div className="goaldivs">
              <select
                type="text"
                name="name"
                id="name"
                onChange={(e) => setName(e.target.value)}
                defaultValue={name}
              >
                {names.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="goaldivs">
              <input
                type="text"
                name="title"
                id="title"
                onChange={(e) => setTitle(e.target.value)}
                defaultValue={title}
              ></input>
            </div>
            <input
              type="checkbox"
              name="abs"
              id="abs"
              onChange={(e) => setAbs(!abs)}
              defaultChecked={abs}
            />
            <button type="submit" style={{ display: "none" }}></button>
            <button
              onClick={() => dispatch(deleteMovie(movie._id))}
              className="close"
            >
              X
            </button>
          </form>
        </>
      ) : (
        <>
          <div className="goaldivs">{movie.date} </div>
          <div className="goaldivs">
            <b>{abs ? "No Movie" : movie.name}</b>
          </div>
          <div className="goaldivs">
            <i> {movie.title}</i>
          </div>
        </>
      )}
      {edit ? (
        <>
          {" "}
          <button
            onClick={() => setIsEditing(!isEditing)}
            className="edit"
          ></button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default MovieItem;
