//19:30
import axios from "axios";

const API_URL = "/api/movies/";
const BOT = "/api/disc/";

//Create new movie
const createMovie = async (movieData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, movieData.fields, config);
  // console.log(movieData.fields);
  return response.data;
};

//Get all movies
const getMovies = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);
  const res = response.data.reverse();
  return res;
};

//Delete goal
const deleteMovie = async (movieId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + movieId, config);
  return response.data;
};

//Update goal
const updateMovie = async (movie, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { _id, name, abs, title, date, datef } = movie;
  // console.log(movie);

  const response = await axios.put(
    API_URL + _id,
    { name, abs, title, datef, date },
    config
  );
  return response.data;
};
//Send call to bot
const schedAnnouncer = async (path, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const route = path ? `${BOT}${path}/` : BOT;
  const response = await axios.get(route, config);
  // console.log(`sent`);
  return response.data;
};

const movieService = {
  createMovie,
  getMovies,
  deleteMovie,
  updateMovie,
  schedAnnouncer,
};

export default movieService;
