import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MovieForm from "../components/MovieForm";
import MovieItem from "../components/MovieItem";
import Spinner from "../components/Spinner";
import { getMovies, reset } from "../features/movies/movieSlice";
import { getNames } from "../features/names/namesSlice";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { movies, isLoading, isError, message } = useSelector(
    (state) => state.movies
  );
  const { names } = useSelector((state) => state.names);

  useEffect(() => {
    if (isError) {
      console.log(message);
      return;
    }

    if (!user) {
      navigate("/login");
      return;
    }

    dispatch(getMovies());
    dispatch(getNames());

    if (!isError) {
      dispatch(reset());
    }

    return () => {
      dispatch(reset());
    };
  }, [user, navigate, isError, message, dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <section className="heading">
        <h1>Welcome {user && user.name}</h1>
        <p>Movie Dashboard</p>
      </section>

      <MovieForm names={names} />

      <section className="content">
        {movies.length > 0 ? (
          <div className="goals">
            {movies.map((movie) => (
              <MovieItem
                key={movie._id}
                movie={movie}
                edit={true}
                names={names}
              />
            ))}
          </div>
        ) : (
          <h3> No movies set</h3>
        )}
      </section>
    </>
  );
}

export default Dashboard;
